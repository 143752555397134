/* FOOTER */
footer.footer {
	background-color: $white;
	padding: $gutter-base 0;
	text-align: center;
	color: $white;
	margin-top: $gutter-lg * 2;

	a {
		color: $white;
	}
}
