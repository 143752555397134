/* DROPZONE */
.dropzone {
	margin-top: 15px;
	min-height: 150px;
	border: 0px;
	outline: 2px dashed $lightgrey;
	background: $white;
	padding: 20px 20px;
	border-radius: $border-radius-base;
	color: $lightgrey;
	transition: all 0.2s;
	.dz-message-large {
		display: block;
		font-size: $font-size-large;
	}
	.dz-preview {
		.dz-details {
			.dz-size {
				margin-bottom: 8px;
			}
		}
		.dz-progress {
			top: auto;
			bottom: 40px;
		}
		.dz-remove {
			text-decoration: none;
			font-size: $font-size-small;
			&:after {
			    content: "\f1f8";
			    display: inline-block;
				@extend %fontawesome;
				margin-left: 5px;
			}
		}
		&.dz-file-preview {
			.dz-image {
				background: darken($white, 5%);
			}
			&.dz-success {
				.dz-image {
					background: transparentize(#43d200, 0.3);
				}
			}
		}
	}
	&.dz-drag-hover {
		border: 0px;
		outline: 2px dashed $brand-primary;
		background: darken($white, 1%);
		transform: scale(1.01);
		.dz-message {
			opacity: 1;
		}
	}
}
