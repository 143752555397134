/**
 * WORDPRESS THEME SASS FILES INSTRUCTIONS
 *
 *	1. Update the theme name, theme description and tags
 *	2. Update the website url to localhost/{folder} if required
 *	3. Update the theme_dir
 *	4. Page styles should all be loaded into the pages folder
 *	5. Components - such as the sidebar, widgets etc - should be loaded into the components folder
 *	6. During testing this file can remain uncompressed
 *	7. When ready for the production environment the enviroment variable should be updated
 *	8. Compress the output to remove this comment and minify the final output
 *
 *	NB: To confirm to WordPress standards, ensure the CSS version of this file is rendered out
 *		into the root as ~/style.css
 */

// Import vars
@import "vars";

// Mixins
@import "mixins";

// Skeleton framework
@import "skeleton";

// General
@import "general";

// Components
@import "components/alert";
@import "components/awards";
@import "components/buttons";
@import "components/code";
@import "components/comments";
@import "components/demo";
@import "components/dropzone";
@import "components/forms";
@import "components/hero-banner";
@import "components/lists";
@import "components/loading";
@import "components/modal";
@import "components/navigation";
@import "components/sponsor";
@import "components/tables";
@import "components/typography";

// Regions
@import "regions/footer";
@import "regions/header";
@import "regions/main";

::-moz-selection {
	background: transparentize($brand-primary, .6);
}

::selection {
	background: transparentize($brand-primary, .6);
}
