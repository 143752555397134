/* Modal */
.modal-open {
    overflow: hidden;
}
.modal {
    display: none;
    overflow: hidden;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    -webkit-overflow-scrolling: touch;
    outline: 0;
    h3 {
        font-weight: bold;
    }
    .modal-dialog {
        position: relative;
        width: auto;
        margin: 10px;
        @include from-width($sm) {
            width: 80%;
            margin: 30px auto;
        }
        .modal-content {
            position: relative;
            background-color: $white;
            border-radius: $border-radius-base;
            -webkit-background-clip: padding-box;
                    background-clip: padding-box;
            outline: 0;
            .modal-header {
                padding: 15px;
                background: darken($white, 7%);
                .modal-title {
                    margin: 0;
                    line-height: 1.42857143;
                }
                .close {
                    margin-top: -2px;
                    -webkit-appearance: none;
                    padding: 0;
                    cursor: pointer;
                    background: 0 0;
                    border: 0;
                    float: right;
                    font-size: 21px;
                    font-weight: 700;
                    line-height: 1;
                    color: #000;
                    text-shadow: 0 1px 0 #fff;
                    filter: alpha(opacity=20);
                    opacity: .2
                }
            }
            .modal-body {
                position: relative;
                padding: 15px;
                .sm8 {
                    border-right: 1px solid darken($white, 10%);
                    margin-right: 15px;
                }
            }
            .modal-footer {
                padding: 15px;
                text-align: right;
                background: darken($white, 7%);
                .btn + .btn {
                    margin-left: 5px;
                    margin-bottom: 0;
                }
                .btn-group .btn + .btn {
                    margin-left: -1px;
                }
                .btn-block + .btn-block {
                    margin-left: 0;
                }
            }
        }
    }
}
.modal.fade .modal-dialog {
    -webkit-transform: translate(0, -25%);
    -ms-transform: translate(0, -25%);
    -o-transform: translate(0, -25%);
    transform: translate(0, -25%);
    -webkit-transition: -webkit-transform 0.3s ease-out;
    -o-transition: -o-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
}
.modal.in .modal-dialog {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
}
.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}
.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000000;
    &.fade {
        opacity: 0;
        filter: alpha(opacity=0);
    }
    &.in {
        opacity: 0.5;
        filter: alpha(opacity=50);
    }
}
.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
}
@media (min-width: $sm) {
    .modal-sm {
        width: 300px;
    }
}
@media (min-width: $md) {
    .modal-lg {
        width: 900px;
    }
}

.clearfix:before,
.clearfix:after,
.modal-header:before,
.modal-header:after,
.modal-footer:before,
.modal-footer:after {
    content: " ";
    display: table;
}
.clearfix:after,
.modal-header:after,
.modal-footer:after {
    clear: both;
}
