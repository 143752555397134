/* Lists */
ul {
  list-style: disc inside; }
ol {
  list-style: decimal inside; }
ol, ul {
  padding-left: 0;
  margin-top: 0; }
ul ul,
ul ol,
ol ol,
ol ul {
  margin: 1.5rem 0 1.5rem 3rem;
  font-size: 90%; }
li {
  margin-bottom: 1rem; }

.award-entries {
    > li {
        &:not(:last-child) {
            margin-bottom: $gutter-lg;
            padding-bottom: $gutter-lg;
            border-bottom: 1px solid darken($white, 3%);
        }
        em {
            margin-left: $gutter-lg;
        }
        .award-entries__entries,
        .award-entries__content {
            display: block;
            margin: 0px;
            margin-top: $gutter-lg;
            margin-left: $gutter-xl;
        }
        ul.award-entries__entries {
            list-style: none;
            li {
                &:before {
                    content: "\f15b";
                    display: inline-block;
                    @extend %fontawesome;
                    margin-right: $gutter-base;
                    color: $brand-primary;
                }
            }
        }
    }
}

ul.already-entered {
    list-style: none;
    li {
        &:before {
            content: "\f15b";
            display: inline-block;
            @extend %fontawesome;
            margin-right: $gutter-base;
            color: $brand-primary;
        }
    }
}
