/* HERO BANNER */
.hero-banner {
	min-height: 350px;
	box-sizing: border-box;
	position: relative;
	overflow: hidden;
	&:before {
	    content: "";
	    display: block;
	    background-color: black;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		opacity: 0.5;
	}
	img {
		max-width: 100%;
	    position: absolute;
	    z-index: -1;
	    left: -100%;
	    right: -100%;
	    margin: auto;
	    min-width: 900px;
		bottom: 0;
	}
	.container {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 0;
		right: 0;
	}
	.award-title {
		color: $white;
		padding-left: 0px;
		text-align: center;
		font-size: $font-size-large;
		@include from-width($sm) {
		    font-size: 40px;
		}
		&:before {
			display: none;
		}
	}
}
