/* Code */
code {
    padding: .2rem .5rem;
    margin: 0 .2rem;
    font-size: 90%;
    white-space: nowrap;
    background: $grey;
    color: $white;
    border: 1px solid #E1E1E1;
    border-radius: $border-radius-base;
}
pre {
    max-width: 100%;
    overflow: hidden;
    code {
        display: block;
        padding: 1rem 1.5rem;
        white-space: pre;
        overflow: scroll;
    }
}
