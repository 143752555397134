/* NAVIGATION */
header.header {
	.container {
		@include from-width($sm) {
		    position: relative;
		}
	}
	nav.nav {
		position: absolute;
	    right: 15px;
	    top: 50%;
	    transform: translateY(-50%);
		text-transform: uppercase;
		letter-spacing: 1px;
		font-weight: 500;
		ul {
			list-style: none;
			float: right;
			margin-bottom: 0px;
			li {
				margin-bottom: 0px;
				display: inline-block;
				&:not(:last-child) {
					margin-right: $gutter-lg;
				}
				a {
					text-decoration: none;
				}
				&.current_page_item {
					a {
						color: $brand-primary;
					}
				}
			}
		}
	}
}
