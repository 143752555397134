/* GENERAL STYLES */
body {
	margin: 0px;
}

div#app {
	main {
		margin: $gutter-lg * 2 0px;
		display: block;
	}
}
