/* SPONSOR */
.sponsor-logo {
	max-width: 200px;
	max-height: 250px;
	width: auto;
	height: auto;
	display: table;
	margin: auto;
	padding-top: $gutter-lg * 2;
}
