/* TYPOGRAPHY */
h1, h2, h3, h4, h5, h6 {
	margin-top: 0;
	margin-bottom: $gutter-base;
	font-weight: 500;
}
h1 {
	font-size: $font-size-h1;
	font-family: $font-family-second;
	font-weight: 600;
	margin-bottom: $gutter-lg;
}
h2 {
	font-size: $font-size-h2;
	font-family: $font-family-second;
	font-weight: 600;
}
h3 {
	font-size: $font-size-h3;
	font-family: $font-family-second;
	font-weight: 600;
	margin-top: 30px;
}
h4 {
	font-size: $font-size-h4;
}
h5 {
	font-size: $font-size-h5;
}
h6 {
	font-size: $font-size-h6;
}

.award-title {
	font-size: $font-size-medium;
	text-transform: uppercase;
	position: relative;
	text-align: center;
	word-break: break-word;
	@include from-width($sm) {
		font-size: $font-size-large;
	    padding-left: 70px;
		text-align: left;
	}
	&:before {
	    content: "\f091";
		color: $award;
	    font-size: 50px;
		display: block;
		margin-bottom: $gutter-base;
		@extend %fontawesome;
		@include from-width($sm) {
			position: absolute;
			left: 0px;
			display: inline-block;
			margin-right: 30px;
			margin-bottom: 0px;
			top: 50%;
			transform: translateY(-50%);
		}
	}
}

/* LINKS */
a {
	color: $brand-primary;
}
a:hover {
	color: darken($brand-primary, 10%);
}

/* ELEMENTS */
strong {
	font-weight: 600;
}

p {
	margin-top: 0;
	&.small {
		color: #a3a3a3;
		font-size: em-calc(11px);
		font-style: italic;
		margin-bottom: 0;
	}
}
