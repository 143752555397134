/* HEADER */
header.header {
	background-color: $black;
	padding: $gutter-base 0px;
	color: $white;
	width: 100%;
	z-index: 9999;
	position: relative;
	a {
		color: $white;
	}
	.navbar-header {
		width: 80px;
		height: 60px;
		z-index: 1;
		.navbar-brand {
			text-indent: 100%;
			overflow: hidden;
			white-space: nowrap;
			display: block;
			width: 100%;
			height: 100%;
			@include ico('logo-white', cover, center center, transparent, no-repeat);
		}
	}
}
