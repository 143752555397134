/* Forms */
form {
	margin-bottom: $gutter-lg;
	.send-yourself-copy {
		float: right;
	}
	.form-group {
		margin-bottom: $gutter-lg;
		label,
		legend {
			display: block;
			margin-bottom: $gutter-sm;
			font-weight: 600;
			font-size: $font-size-small;
			padding: 0px;
		}
		.form-input,
		input,
		label {
			width: 100%;
		}
	}
	p {
		margin-bottom: $gutter-lg;
		&:last-child {
			margin-bottom: $gutter-lg * 2;
		}
	}
}

input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea,
select {
	height: 38px;
	padding: 6px 10px;
	background-color: $white;
	border: 0px;
	border-bottom: 2px solid $text-color;
	border-radius: 0px;
	box-shadow: none;
	box-sizing: border-box;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	transition: border-color 0.2s;
	&:focus {
		border-color: $brand-primary;
		outline: 0;
	}
}

select {
	padding-right: 35px;
	background: {
		image: url('#{$website_url}#{$theme_dir}/images/caret.png');
		repeat: no-repeat;
	    size: 8px;
	    position: center right 15px;
	}
}
.checkbox {
	strong {
		font-size: $font-size-h3;
	}
	input[type="checkbox"] {
		display: none;
		&~ .checkbox-box {
			&:before {
				content: "";
				width: 20px;
				height: 20px;
				display: inline-block;
				vertical-align: sub;
				transition: background 0.2s;
				margin-left: 15px;
				background: {
					image: url('/images/checkbox-unchecked.png');
					size: contain;
					repeat: no-repeat;
				}
			}
		}
		&:checked {
			&~ .checkbox-box {
				&:before {
					background: {
						image: url('/images/checkbox-checked.png');
					}
				}
			}
		}
	}
	&.declaration {
		.checkbox-box {
			&:before {
				float: right;
			}
		}
	}
}


.form-group {
	&.repeatable {
		margin-bottom: 0;
	}
	input[type="file"] {
		display: none;
		&~ label.file-input {
			height: 48px;
			line-height: 48px;
			padding: 0px 40px;
			border: 1px solid darken($white, 20%);
			font-weight: 400;
			border-radius: $border-radius-base;
			box-sizing: border-box;
			text-align: center;
			display: inline-block;
			width: auto;
			margin-bottom: 0px;
			position: relative;
			i.fa {
				margin-right: 5px;
			}
			.del-q {
				display: none;
			}
			&.populated {
				text-align: left;
				padding: 0px;
				height: 70px;
				line-height: 70px;
				.del-q {
					display: block;
					position: absolute;
					right: 0;
					top: 50%;
					transform: translateY(-50%);
					width: 40px;
					&:before {
					    content: "\f00d";
					    @extend %fontawesome;

					}
				}
				i.fa {
					margin-right: 20px;
					&:before {
						content: '\f15b';
					}
				}
				@include states() {
					background: none;
				}
			}
		}
	}
}

.files {
	border: 1px solid darken($white, 20%);
	padding: $gutter-lg;
	margin-bottom: $gutter-base;
	.files-wrap {
		.form-group {
			&:not(:last-child) {
				border-bottom: 1px solid darken($white, 10%);
			}
			label.file-input {
				width: 100%;
				border: 0px;
				@include states() {
					background: darken($white, 5%);
				}
			}
		}
	}
	button#add-q {
		display: block;
		width: 100%;
		text-align: left;
	}
}

.form-group.yes-no {
	@include from-width($sm) {
		display: table;
		width: 100%;
	}
	.control-label,
	.input-yes-no {
		@include from-width($sm) {
			display: table-cell;
			width: auto;
		}
	}
	.input-yes-no {
		text-align: right;
		width: 1%;
	}
}
.input-yes-no {
	display: table;
	input[type="radio"] {
		display: none;
		&[value="Yes"] {
			+ label {
				&:before {
					transform: translateX(100%);
				}
			}
		}
		+ label {
			padding: 0px $gutter-lg;
			height: 38px;
			line-height: 38px;
			display: table-cell;
			text-align: center;
			position: relative;
			z-index: 2;
			width: 1%;
			cursor: pointer;
			margin-bottom: 0px;
			background: darken($white, 10%);
			color: darken($white, 40%);
			overflow: hidden;
			transition: all 0.2s;
			@include states() {
				cursor: pointer;
			}
			&:first-child {
				border-top-left-radius: $border-radius-base;
				border-bottom-left-radius: $border-radius-base;
			}
			&:before {
				content: '';
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				background-color: $brand-primary;
				z-index: -1;
				transform: translateX(-100%);
				transition: all 0.2s ease-in-out;
			}
		}
		&:checked {
			+ label {
				z-index: 3;
				color: $white;
				&:before {
					opacity: 1;
					transform: translateX(0%);
					transition: 0.2s ease-in-out;
				}
			}
		}
	}
}


textarea {
	min-height: 65px;
	padding-top: 6px;
	padding-bottom: 6px;
	max-width: 100%;
}

fieldset {
	padding: 0;
	border-width: 0;
}

input[type="checkbox"],
input[type="radio"] {
	display: inline;
}

label > .label-body {
	display: inline-block;
	margin-left: .5rem;
	font-weight: normal;
}
