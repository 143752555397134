/* AWARDS */
ul.awards {
	list-style: none;
	font-size: 20px;
	margin-top: 15px;
	margin-bottom: 0px;
	color: $white;
	text-align: center;
	li.awards__prize {
		display: inline-block;
		margin-right: $gutter-lg;
		margin-bottom: 0px;
		&:before {
		    content: "\f091";
		    display: inline-block;
		    @extend %fontawesome;
			margin-right: $gutter-base;
		}
		&.awards__prize--first {
			&:before {
				color: gold;
			}
		}
		&.awards__prize--second {
			&:before {
				color: silver;
			}
		}
	}
}
