/* DEMO */
.demo {
	.demo-step {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-direction: column;
		@include from-width($sm) {
		    flex-direction: row;
		}
		.demo-instructions {
			margin-right: 30px;
		}
		img {
			max-width: 100%;
			@include from-width($sm) {
				max-width: 50%;
			}
		}
	}
}

// Demo button on digital
.digital {
	.pull-right {
		margin-bottom: 15px;
		&:hover {
			text-decoration: underline;
			cursor: pointer;
		}
		img {
			margin-top: -2px;
			margin-left: 5px;
    		vertical-align: middle;
		}
	}
	.dropzone {
		clear: both;
	}
}
