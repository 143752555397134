/* Buttons
–––––––––––––––––––––––––––––––––––––––––––––––––– */
button {
	background: none;
	@include states() {
		outline: none;
	}
}
.btn,
button.btn {
	display: inline-block;
	height: 38px;
	padding: 0 30px;
	color: $text-color;
	text-align: center;
	font-size: $font-size-smaller;
	font-weight: 600;
	line-height: 38px;
	letter-spacing: 1px;
	text-transform: uppercase;
	text-decoration: none;
	white-space: nowrap;
	background-color: transparent;
	border-radius: $border-radius-base;
	border: 1px solid $lightgrey;
	cursor: pointer;
	box-sizing: border-box;
	vertical-align: top;
	@include states() {
		color: $black;
		border-color: darken($lightgrey, 10%);
		outline: 0;
	}
	&.btn--clean {
		background: none !important;
		border: none !important;
		padding: 0px !important;
		color: $black;
		@include states() {
			color: lighten($black, 10%);
		}
	}
	&.btn--primary,
	&.btn-primary {
		color: $white;
		background-color: $brand-primary;
		border-color: darken($brand-primary, 2%);
		@include states() {
			color: $white;
			background-color: darken($brand-primary, 5%);
			border-color: darken($brand-primary, 5%);
			&:active {
				background-color: darken($brand-primary, 10%);
			}
		}
		&.btn--clean {
			color: $brand-primary;
		}
	}
	&.btn--secondary,
	&.btn-secondary {
		color: $white;
		background-color: $brand-secondary;
		border-color: darken($brand-secondary, 2%);
		@include states() {
			color: $white;
			background-color: darken($brand-secondary, 5%);
			border-color: darken($brand-secondary, 5%);
			&:active {
				background-color: darken($brand-secondary, 10%);
			}
		}
		&.btn--clean {
			color: $brand-secondary;
		}
	}
	&.btn--success,
	&.btn-success {
		color: $white;
		background-color: $brand-success;
		border-color: darken($brand-success, 2%);
		@include states() {
			color: $white;
			background-color: darken($brand-success, 5%);
			border-color: darken($brand-success, 5%);
			&:active {
				background-color: darken($brand-success, 10%);
			}
		}
		&.btn--clean {
			color: $brand-success;
		}
	}
	&.btn--warning,
	&.btn-warning {
		color: $white;
		background-color: $brand-warning;
		border-color: darken($brand-warning, 2%);
		@include states() {
			color: $white;
			background-color: darken($brand-warning, 5%);
			border-color: darken($brand-warning, 5%);
			&:active {
				background-color: darken($brand-warning, 10%);
			}
		}
		&.btn--clean {
			color: $brand-warning;
		}
	}
	&.btn--error,
	&.btn-error {
		color: $white;
		background-color: $brand-error;
		border-color: darken($brand-error, 2%);
		@include states() {
			color: $white;
			background-color: darken($brand-error, 5%);
			border-color: darken($brand-error, 5%);
			&:active {
				background-color: darken($brand-error, 10%);
			}
		}
		&.btn--clean {
			color: $brand-error;
		}
	}
	&.btn--center {
		display: table;
		margin: auto;
	}
}

.form-group {
	button.btn,
	a.btn {
		width: 100%;
		@include from-width($sm) {
		    width: auto;
		}
		&:not(:last-child) {
			margin-bottom: $gutter-base;
		}
	}
}
