/**
 * CUSTOM VARIABLES
 *
 *	All variables should be configured in this file.
 *	Use variables rather than hard values to maintain consistency throughout the design
 *	and allow more flexiblity when updating and making tweaks.
 *
 */

// Enviroment
$dev_environment:			false;
$website_url: 				if($dev_environment != true, "https://awards.beerguild.co.uk", "http://127.0.0.1:8000");
$theme_dir:					'';

// Colours
$white:						#fff;
$black:						#1f1f1f;
$blue:						#5bc0de;
$darkblue:					#030e35;
$green:						#5cb85c;
$yellow:					#fab039;
$red:						#d9534f;
$pink:						#e50065;
$orange:					#F7931E;

$lightgrey:					#bbb;
$grey:						#4D4D4D;

$brand-primary:				$yellow;
$brand-secondary:			darken($yellow, 15%);
$brand-success:				$green;
$brand-warning:				$yellow;
$brand-error:				$red;

$award:						$yellow;

$text-color:				$black;

// Breakpoints and grid
$xs:						540px;
$sm:						720px;
$md:						960px;
$lg:						1140px;

$gutter-base: 				15px;
$gutter-sm: 				7.5px;
$gutter-lg:					30px;
$gutter-xl:					60px;

// Fonts
$font-family-base : 		'Open Sans', sans-serif;
$font-family-second : 		'Josefin Sans', sans-serif;
$font-family-third : 		'Libre Baskerville', sans-serif;

$font-size-vlarge:			50px;
$font-size-large:			30px;
$font-size-medium:			20px;
$font-size-base:			14px;
$font-size-small:			14px;
$font-size-smaller:			11px;

$line-height-base:			1.6;

$font-size-h1:				30px;
$font-size-h2:				30px;
$font-size-h3:				16px;
$font-size-h4:				14px;
$font-size-h5:				14px;
$font-size-h6:				10px;

// Radius
$border-radius-base:		0px;
